$(".page-load-fade-in").each(function(i) {
	var animation = 'fadeInUp';
	if($(this).hasClass('home-background'))
		animation = 'fadeIn';
	$(this).delay(1500 * i).queue(
		function() {
			$(this).addClass("animated " + animation).dequeue();
	});
});

$(".member, #images-wrapper img, .fade-in-element").each(function(i) {
	var animation = 'fadeInUp';
	$(this).delay(50 * i).queue(
		function() {
			$(this).addClass("animated " + animation).dequeue();
	});
});
$(window).on('load', function(){
	$('.grid').masonry({
		// options
		itemSelector: '.grid-item',
		columnWidth: 325
	});
});


